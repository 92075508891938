<template>
  <div><v-btn  v-if="ulogin" @click="ulogout()">
            Logout
          </v-btn></div>
 
</template>

<script>

import {sync,dispatch } from "vuex-pathify";

  export default {
    name: 'DefaultAccount',

    data: () => ({
      profile: [
        // { title: 'Profile' },
        // { title: 'Settings' },
        // { divider: true },
        { title: 'Log out' },
      ],
    }),

      computed: {
    ...sync("user", ["ulogin",]),
  },


    methods:{
      async ulogout(){
        await dispatch('user/logoutUser')
        await this.$router.push({name:'login'})

        if(!this.ulogin)
          await this.$toast.success("Logout Successfull");
        else{
          await this.$toast.error("Logout Failed");
        }
      }

    }
  }
</script>
